<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark color="">
      <v-card-title>
        <div class="text--h6">{{ $t("promotions.filter.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-form autocomplete="off">
          <v-text-field
            :label="$t('promotions.fields.name.title')"
            v-model="filters.name.value"
            clearable
          ></v-text-field>
          <v-text-field
            :label="$t('promotions.fields.identifier.title')"
            v-model="filters.identifier.value"
            clearable
          ></v-text-field>
          <v-select
            :label="$t('promotions.fields.enabled.title2')"
            v-model="filters.enabled.value"
            :items="statuses"
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetFilter()" color="third" text>{{
          $t("btn.reset")
        }}</v-btn>
        <v-btn @click="applyFilter()" color="secondary" text>{{
          $t("btn.filter")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const defaultFilters = {
  name: {
    value: null,
  },
  identifier: {
    value: null,
  },
  enabled: {
    value: null
  }
};

export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
  },
  data: () => ({
    filters: Vue._.cloneDeep(defaultFilters),
  }),
  methods: {
    applyFilter() {
      this.$emit("applyFilter", this.filters);
      this.dialog.display = false;
    },
    resetFilter() {
      this.filters = Vue._.cloneDeep(defaultFilters);
      this.$emit("applyFilter", {});
      this.dialog.display = false;
    },
  },
  computed: {
    ...mapGetters({ statuses: "statuses" }),
  },
};
</script>