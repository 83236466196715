<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("promotions.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilters()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div
          :class="{
            'mr-auto': $vuetify.breakpoint.xsOnly,
            'ml-auto': $vuetify.breakpoint.smAndUp,
          }"
          class="mb-2"
        >
          <v-btn
            color="primary"
            class="mr-2"
            :to="$i18n.getRouteLink('promotion-upload')"
            v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
          >
            <v-icon left>mdi-file-upload-outline</v-icon>
            <span>{{ $t("btn.uploadFile") }}</span>
          </v-btn>
          <v-btn
            color="primary"
            v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
            :to="$i18n.getRouteLink('promotion-add')"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("promotions.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.promotion", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :server-items-length="meta.total"
        sort-by="id"
        sort-desc
        dark
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>
                    <v-chip class="table-chip" color="third">
                      <span class="black--text">{{ item.identifier }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <span>{{ item.name }}</span>
                  </td>
                  <td style="min-width: 160px">
                    <span v-if="item.expired">{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <v-chip
                      class="table-chip"
                      color="success"
                      v-if="item.enabled"
                      >{{ $t("promotions.fields.enabled.enabled") }}</v-chip
                    >
                    <v-chip class="table-chip" color="danger" v-else>{{
                      $t("promotions.fields.enabled.disabled")
                    }}</v-chip>
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td style="min-width: 130px" class="text-right">
                    <template
                      v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :to="{
                              name: 'promotion-edit',
                              params: { id: item.id },
                            }"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            color="third"
                          >
                            <v-icon color="third">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("resellers.list.actions.edit") }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="deletePromotion(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon color="third">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("promotions.list.actions.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <promotion-filter
      :dialog="dialog.filter"
      @applyFilter="filterItems"
    ></promotion-filter>
    <promotion-confirm-delete
      :dialog="dialog.delete"
      @confirm="confirmDeletePromotion()"
    ></promotion-confirm-delete>
  </div>
</template>

<script>
import PromotionFilter from "./filter";
import ListMixin from "./../../mixins/commons/list";
import PromotionConfirmDelete from "./../commons/confirm";
import { mapActions } from "vuex";

export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/v1/promotions",
    dialog: {
      filter: {
        display: false,
      },
      delete: {
        display: false,
        message: null,
      },
    },
    promotion: {},
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("promotions.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("promotions.fields.identifier.title"),
          align: "start",
          sortable: true,
          value: "identifier",
          width: 80,
        },
        {
          text: this.$t("promotions.fields.name.title"),
          align: "start",
          sortable: true,
          value: "name",
          width: 160,
        },
        {
          text: this.$t("promotions.fields.expiration.title"),
          align: "start",
          sortable: true,
          value: "expired",
          width: 160,
        },
        {
          text: this.$t("promotions.fields.enabled.title2"),
          align: "start",
          sortable: true,
          value: "enabled",
          width: 80,
        },
        {
          text: this.$t("promotions.fields.description.title"),
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: null,
          width: 130,
        },
      ];
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    deletePromotion(promotion) {
      this.promotion = promotion;
      this.dialog.delete.message = this.$t("promotions.delete.confirm.title");
      this.dialog.delete.display = true;
    },
    async confirmDeletePromotion() {
      if (!this.promotion.id) return;
      try {
        await this.request({
          url: `api/v1/promotions/${this.promotion.id}`,
          method: "DELETE",
          messages: {
            204: this.$t("promotions.delete.success"),
            500: true,
          },
        });
        this.getItems();
      } catch (error) {
        // empty
      }
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  components: {
    PromotionFilter,
    PromotionConfirmDelete,
  },
};
</script>