<template>
  <v-dialog
    v-model="dialog.display"
    persistent
    max-width="300"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-text class="pt-5">
        <div class="text-body-1 font-weight-bold">{{ dialog.message }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="font-weight-bold" @click="close()" color="thrid">{{
          $t("btn.no")
        }}</v-btn>
        <slot name="body"></slot>
        <v-btn
          text
          class="font-weight-bold"
          color="secondary"
          @click="confirm()"
          >{{ $t("btn.yes") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          message: "",
        };
      },
    },
  },
  data: () => ({}),
  methods: {
    confirm() {
      this.$emit("confirm", true);
      this.dialog.display = false;
    },
    close() {
      this.dialog.display = false;
      this.$emit("cancel", true);
    },
  },
};
</script>