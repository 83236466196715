<template>
  <div>
    <promotion></promotion>
  </div>
</template>

<script>
import Promotion from "./../../components/promotions/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("promotions.title")
    };
  },
  components: {
    Promotion
  }
};
</script>